<template>
    <div class="news_en_div">
        <div class="news_banner">
            <img :src="imgSrc[0]"/>
        </div>
        <div class="news_content_div allContentWidth">
            <div class="industry_trends_box">
                <p class="industry_trends_title">Company News</p>
                <p class="industry_trends_content" v-for="(item,index) in contentAll.company" :key="index">
                    <span class="content_span" @click="childClickFun('company',item)">{{ item.content }}</span>
                    <span>{{ item.time }}</span>
                </p>
            </div>
            <div class="industry_trends_box">
                <p class="industry_trends_title">Industry Dynamics</p>
                <p class="industry_trends_content" v-for="cItem in contentAll.industry" :key="cItem.id">
                    <span class="content_span" @click="childClickFun('industry',cItem)">{{ cItem.content }}</span>
                    <span>{{ cItem.time }}</span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import utils from '@/libs/utils';

export default {
    components: {},
    computed: {},
    mounted() {
    },
    watch: {},
    methods: {
        childClickFun(arg, item) {
            utils.scrollTo();
            switch (arg) {
                case 'company':
                    this.$router.push(`/company_news_en/` + item.id);
                    break;
                case 'industry':
                    this.$router.push(`/industry_trends_en/` + item.id);
                    break;
            }
        },
    },
    data() {
        var imgSrc = [];
        imgSrc.push(require('../../assets/img/aboutUs/about_banner.png'));//(0)
        return {
            imgSrc: imgSrc,
            contentAll: {
                'industry': [
                    {id: 11, content: 'Happy NEW YEAR', time: '2024-2'},
                    {id: 10, content: 'With the rising Sun and the fluttering Flag. Chinese Community Party has established for 74 years in the year of 2023! Let\'s  celebrate this special national holiday together. We never forget what our proneers done for us to give us a safe and strong country to live! May our motherland China more prosperous and powerful!', time: '2023-9'},
                    {id: 9, content: 'Blockbuster Trade', time: '2023-5'},
                    {id: 8, content: "China's railway cargo delivery volume reached 3.9 billion tonnes in 2022, up 4.7% year on year.", time: '2023-1'},
                    {id: 7, content: 'SHANGHAI PORT REMAIN NO.1 IN 2022', time: '2023-1'},
                    {id: 4, content: 'Are Spot Rates an Effective Metric for Ocean Freight?', time: '2022-7-15'},
                    {id: 2, content: 'HMM Plans $11 Billion Investment for Growth...', time: '2022-7-14'},
                    {id: 5, content: 'Shipping’s Next Fuel of Choice Could be...', time: '2022-7-14'},
                    {id: 3, content: 'Berge Bulk Adds Rotor Sails to Two Giant Bulkers', time: '2022-7-13'},
                    {id: 1, content: 'Surplus of Six Million TEU Built Up by Carriers Will Recede', time: '2022-7-12'},
                    {id: 6, content: 'New Bill Would Empower EPA to Regulate CO2, But...', time: '2022-7-11'},
                ],
                'company': [
                    {id: 9, content: 'Eastrong AGM 2024', time: '2024-2'},
                    // {id: 8, content: 'Warm congratulations on Eastrong International Logistics Co., LTD. of Taiwan office set up', time: '2023-9'},
                    {id: 7, content: 'Team Building in Chengdu 2023', time: '2023-5'},
                    {id: 6, content: 'Eastrong Global Sales Meeting 2023', time: '2023-5'},
                    {id: 5, content: 'EASTRONG GROUP OBTAINED CCLP（CargoWise Certified Logistics Provider）CERTIFICATION', time: '2023-1'},
                    {id: 4, content: 'Eastrong Malaysia Launched Local Warehousing & Transportation Service', time: '2023-1'},
                    {id: 3, content: 'Eastrong Shanghai held a birthday party for their employee...', time: '2022-08-26'},
                    {id: 1, content: 'Eastrong Shanghai Branch held a birthday party...', time: '2022-6-24'},
                    {id: 2, content: 'Eastrong Shanghai Branch was awarded CEIV certification', time: '2022-3-3'},
                ],
            }
        }
    }
}
</script>
<style lang="less">
.news_en_div {
    .news_banner {
        width: 100%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .news_content_div {
        display: flex;
        padding: 0 15px;

        .industry_trends_box {
            margin: 40px 0 60px 0;
            width: 50%;

            .industry_trends_title {
                font-size: 24px;
                font-weight: 700;
                margin: 0 0 20px 0;
            }

            .industry_trends_content {
                line-height: 50px;
                display: flex;
                justify-content: space-between;

                .content_span {
                    width: 75%;
                }

                // &:hover{
                //     .content_span {
                //         cursor: pointer;
                //         text-decoration:underline;
                //     }
                // }
            }
        }

        .industry_trends_box { //:first-child
            padding-right: 20px;

            .industry_trends_content {
                &:hover {
                    .content_span {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .news_en_div {
        .news_content_div {
            flex-direction: column;

            .industry_trends_box {
                flex-direction: column;
                width: 100%;
            }

            .industry_trends_box:first-child {
                padding-right: 0px;
            }
        }
    }
}
</style>
